.input {
  display: block;
  padding: 9px;
  height: 50px;
  width: 100%;
  font-size: 16px;
  line-height: 1;

  &:focus {
    border: 2px solid #f3c959;
    outline: none;
  }
}
.inputError {
  border-color: red;
}
