.toggleCell {
  border-top: 1px solid #c8c8c8;
  &.off__bottom {
    margin-bottom: 0;
  }
}
.option {
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 0px 20px;
  margin-bottom: 10px;
}
.selected {
  border: 2px solid #f3c959;
}
.item {
  margin: 20px 0px;
}
.radioInput {
  display: none;
}
.radioInput + label {
  display: block;
  width: 100%;
  padding-left: 35px;
  position: relative;
  margin-right: 15px;
  display: block;
}
.radioInput + label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
}
.radioInput:checked + label:before {
  border: 0;
  background-color: #fac83c;
}

.radioInput:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 7px;
  height: 11px;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.labelTwoRows {
  .radioInput + label:before {
    bottom: 0;
  }
  .radioInput:checked + label:after {
    top: 17px;
  }
}

.disabled {
  background-color: #f0f0f0;
}
