.colContainer {
  gap: 20px;
}

.rowContainer {
  gap: 10px;
}

.outlinedUnselected {
  outline: 1px solid #dddddd;
  padding: 8px 12px;
  border-radius: 4px;
  & > label {
    margin-right: 0;
  }
}

.outlinedSelected {
  outline: 2px solid #fac83c;
  padding: 8px 12px;
  border-radius: 4px;
  & > label {
    margin-right: 0;
  }
}

.radio {
  input[type="radio"]:disabled + label {
    color: #c0c4cc;
    cursor: not-allowed;
  }
}
