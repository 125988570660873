.root {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  outline: 1px solid #c8c8c8;
  border-radius: 4px;
}

.focused {
  outline: 2px solid #fac83c !important;
}

.root::after {
  display: block;
  right: 10px;
  top: 8px;
  position: absolute;
  pointer-events: none;
  font-size: 19px;
  font-family: "icomoon";
  content: "\f107";
  font-weight: 900;
}

.select {
  width: 100%;
  height: 44px;
  font-size: 16px;
  color: #5a5a5a;
  border: none;
  outline: none;
}
