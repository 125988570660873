.root {
  & input {
    background-color: unset !important;
    color: #5a5a5a !important;
    -webkit-text-fill-color: #5a5a5a !important;
  }
  &Disabled {
    background-color: #f0f0f0;
    & input {
      -webkit-text-fill-color: #5a5a5a !important;
    }
  }
}

.mobileActionBar {
  & button {
    color: #000000;
  }
}
