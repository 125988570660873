.root {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #828282;
  position: relative;
  width: 52px;
  height: 32px;
  border-radius: 32px;
  transition: 0.3s ease all;

  span {
    background-color: #828282;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 100%;
    transition: 0.3s ease all;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 6px;
      left: 9px;
      width: 7px;
      height: 10px;
      transform: rotate(40deg);
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
      transition: 0.3s ease all;
    }
  }
}

.active {
  border-color: #fac83c;
  background-color: #fac83c;

  span {
    width: 24px;
    height: 24px;
    top: 3px;
    background-color: #fff;
    left: 23px;

    &:after {
      border-color: #fac83c;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.64;
}
