@mixin btn {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: auto;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.primary {
  @include btn;
  & {
    color: #fff;
    background-color: #00508c;
    font-weight: bold;
  }
}

.secondary {
  @include btn;
  & {
    color: #00508c;
    background-color: #fff;
    font-weight: bold;
    border: 1px solid #00508c;
  }
}

.yellow {
  @include btn;
  & {
    background-color: #fac83c;
    font-weight: bold;
  }
}

.blue {
  @include btn;
  & {
    background-color: #00508c;
    font-weight: bold;
    color: #ffffff;
  }
}

.lightblue {
  @include btn;
  & {
    background-color: #8cb4dc;
    font-weight: bold;
    color: #ffffff;
  }
}

.gray {
  @include btn;
  & {
    background-color: #f0f0f0;
    font-weight: bold;
  }
}

.outlined {
  @include btn;
  & {
    background-color: transparent;
    font-weight: bold;
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
  }
}

.md {
  padding: 8px 15px;
  font-size: 16px;
}

.rounded {
  line-height: 44px;
  border-radius: 44px;
  padding: 0 20px;
}

.square {
  height: 44px;
  border-radius: 4px;
}

.fullWidth {
  width: 100%;
}

.sm {
  line-height: 22px;
  font-size: 0.75rem;
  padding: 1px 10px;
}

.xs {
  line-height: 24px;
  border-radius: 24px;
  font-size: 0.75rem;
  padding: 0 10px;
  height: auto;
}

.arrowRight {
  i {
    margin-left: 5px;
  }
}

.arrowLeft {
  i {
    margin-right: 5px;
  }
}
