.root {
  width: 100%;
  height: 44px;
  line-height: 44px;
  background-color: transparent;
  border: none;
  outline: 1px solid #c8c8c8;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 10px;
  color: #5a5a5a;
}

.root:focus {
  outline: 2px solid #f3c959;
  border: none;
}

.error {
  outline: 2px solid #f3c959;
  background-color: #f3c95933;
}

.root:disabled {
  background-color: #cccccc33;
}
