.wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
}
.checkboxInput {
  display: none;
}

.label {
  padding-left: 35px;
  position: relative;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;

  &.font-s {
    font-size: 0.75rem;
    &::after {
      top: 3px;
    }
  }
  &.font-m {
    font-size: 1rem;
    &::after {
      top: 5px;
    }
  }
  &.font-l {
    font-size: 1.25rem;
    &::after {
      top: 7px;
    }
  }
}

.labelNoMargin {
  margin-right: 0 !important;
}

.label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}

.checkboxInput:checked + .label::before {
  border: 0;
  background-color: #fac83c;
}

.checkboxInput:checked + .label::after {
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  width: 7px;
  height: 11px;
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

/* Disabled styles */
.checkboxInput:disabled + .label {
  cursor: not-allowed;

  &::before {
    border-color: #c8c8c8;
    background-color: #f0f0f0;
  }
}
