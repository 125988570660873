.wrapper {
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 12px 20px;
  margin-bottom: 10px;
}
.wrapperChecked {
  border: 2px solid #f3c959;
}
